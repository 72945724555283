import React from 'react'

import Slider from '../componets/slider'
import Brands from '../componets/brands'
import CustomSolutions from '../componets/custom-solutions'
import OptomizedSolutions from '../componets/optomized-solutions'
import Analytics from '../componets/analytics'
import Stratagy from '../componets/stratagy'
import Pricing from '../componets/pricing'
import Stats from '../componets/stats'
import CallToAction from '../componets/call-to-action'
import Contact from '../componets/contact';


function Home(){
    
    return(
        <>
           <Slider />
           <CallToAction /> 
           <Brands />
           <CustomSolutions />
           <OptomizedSolutions />
           <Analytics />
           <Stratagy />
           <Pricing />
           <Stats />
           <Contact />

        </>
    )
}

export default Home;