import React from 'react'

function CustomSolutions(){
    return(
      <section id="solutions" className="content-2 pb-60 content-section division">
				<div className="container">
					<div className="row d-flex align-items-center">


						
						<div className="col-md-5 col-lg-6">
							<div className="rel img-block left-column wow fadeInRight">
								<img className="img-fluid" src="images/custom-dev.jpg" alt="content-image" />
							</div>
						</div>


						
						<div className="col-md-7 col-lg-6">
							<div className="txt-block right-column wow fadeInLeft">

									
					 			<span className="section-id txt-upcase">Custom Solutions</span>

								
								<h2 className="h2-xs">Start your project with Software Alternatives!</h2>

									
								<p className="p-lg">We belive each project is unique and deserves the attention to deatail to bring
									your ideas to life.
								</p>

									
								<p className="p-lg">Our development team has years of knoledge and experiance in all modern web apps, design
									and backends.  Whether your project is a small web page or a compex business application we are 
									here to deliver the product you want. 
								</p>

							</div>
						</div>	


					</div>	   
				</div>	   
			</section>
    )
}

export default CustomSolutions;