import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";

import Home from './pages/home';
import About from './pages/about'

// components
import Header from './componets/header'
import Footer from "./componets/footer";





function App() {

  return (
    <Router>
    <div id="loading">
			<div id="loading-center">
				<div id="loading-center-absolute">
					<div className="object" id="object_one"></div>
					<div className="object" id="object_two"></div>
					<div className="object" id="object_three"></div>
					<div className="object" id="object_four"></div>
				</div>
			</div>
		</div>
    <div id="page" className="page">

      <Header />

      {/* A <Switch> looks through its children <Route>s and
          renders the first one that matches the current URL. */}
      <Switch>
        <Route path="/about">
          <About />
        </Route>
        <Route path="/">
          <Home />
        </Route>
      </Switch>
      <Footer />
    </div>
  </Router>
  );
}

export default App;
