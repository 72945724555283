import React from 'react'

function Header(){
    return(
        			
			<header id="header" className="header tra-menu navbar-light">
				<div className="header-wrapper">

					{/* <!-- MOBILE HEADER --> */}
				    <div className="wsmobileheader clearfix">	  	
				    	<span className="smllogo"><img src="images/logo-blue-01.png" alt="mobile-logo"/></span>
				    	<a id="wsnavtoggle" className="wsanimated-arrow"><span></span></a>	
				 	</div>


				 	{/* <!-- NAVIGATION MENU --> */}
				  	<div className="wsmainfull menu clearfix">
	    				<div className="wsmainwp clearfix">


	    					{/* <!-- HEADER LOGO --> */}
	    					<div className="desktoplogo">
                                <a href="#hero-3" className="logo-black"><img src="images/logo-blue-01.png" alt="header-logo" /></a>
                                </div>
	    					<div className="desktoplogo">
                                <a href="#hero-3" className="logo-white"><img src="images/logo-white-01.png" alt="header-logo" /></a>
                                </div>


	    					{/* <!-- MAIN MENU --> */}
	      					<nav className="wsmenu clearfix">
	        					<ul className="wsmenu-list nav-green-hover">


	        						


	        						
{/* solution, services, analytics stratagy */}

							    	
                                
                                    {/* <!-- SIMPLE NAVIGATION LINK --> */}
							    	<li className="nl-simple" aria-haspopup="true"><a href="#solutions">Solutions</a></li>
                                    {/* <!-- SIMPLE NAVIGATION LINK --> */}
							    	<li className="nl-simple" aria-haspopup="true"><a href="#services">Services</a></li>
                                    {/* <!-- SIMPLE NAVIGATION LINK --> */}
							    	<li className="nl-simple" aria-haspopup="true"><a href="#analytics">Analytics</a></li>

						          	{/* <!-- SIMPLE NAVIGATION LINK --> */}
							    	<li className="nl-simple" aria-haspopup="true"><a href="#stratagy">Stratagy</a></li>


						          	{/* <!-- SIMPLE NAVIGATION LINK --> */}
							    	<li className="nl-simple" aria-haspopup="true"><a href="#pricing-2">Hosting</a></li>
										<li className="nl-simple" aria-haspopup="true"><a href="#contact">Contact</a></li>


							    	


								    


									{/* <!-- HEADER SOCIAL LINKS 													 */}
									<li className="nl-simple white-color header-socials ico-20 clearfix" aria-haspopup="true">
										<span><a target="_blank" href="https://www.facebook.com/SoftwareAlternatives/" className="ico-facebook"><span className="flaticon-facebook"></span></a></span>
										{/* <span><a href="#" className="ico-twitter"><span className="flaticon-twitter"></span></a></span>
										<span><a href="#" className="ico-instagram"><span className="flaticon-instagram"></span></a></span>
										<span><a href="#" className="ico-dribbble"><span className="flaticon-dribbble"></span></a></span>	 */}
									</li>


	        					</ul>
	        				</nav>	


	    				</div>
	    			</div>	


				</div>     
			</header>
    )
}

export default Header;