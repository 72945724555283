import React from 'react'

function Brands(){
    return(
        <div id="brands-1" className="wide-100 brands-section division">
            <div className="container">					
                <div className="row">
                    <div className="col text-center">	


                            
                        <div className="owl-carousel brands-carousel">
                                            
                            
                            <div className="brand-logo">
                                <img className="img-fluid" src="images/asap.png" alt="brand-logo" />
                            </div>
                                                
                            
                            <div className="brand-logo">
                                <img className="img-fluid" src="images/oaw.png" alt="brand-logo" />
                            </div>
                                                
                            
                            <div className="brand-logo">
                                <img className="img-fluid" src="images/dc.png" alt="brand-logo" />
                            </div>
                                                
                            
                            <div className="brand-logo">
                                <img className="img-fluid" src="images/ammozon.png" alt="brand-logo" />
                            </div>
                                                
                            
                            <div className="brand-logo">
                                <img className="img-fluid" src="images/cefco.png" alt="brand-logo" />
                            </div>
                                                
                            
                            <div className="brand-logo">
                                <img className="img-fluid" src="images/themill.png" alt="brand-logo" />
                            </div>

                            
                            <div className="brand-logo">
                                <img className="img-fluid" src="images/camerasonsite.png" alt="brand-logo" />
                            </div>
                                                        
                            
                            <div className="brand-logo">
                                <img className="img-fluid" src="images/citizens.png" alt="brand-logo" />
                            </div>
                            
                            
                            <div className="brand-logo">
                                <img className="img-fluid" src="images/tx6.png" alt="brand-logo" />
                            </div>

                        </div>	


                    </div>
                </div>  
            </div>	
        </div>	
    )
}


export default Brands;