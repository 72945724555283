import React from 'react'

function Stats(){
    return(
        <section id="statistic-3" className="bg-02 statistic-section division">
				<div className="container">
					<div className="statistic-3-wrapper white-color text-center">
						<div className="row row-cols-1 row-cols-sm-2 row-cols-md-4">


							
							<div className="col">						
								<div className="statistic-block mb-40 wow fadeInUp">	

									
									<div className="statistic-ico ico-65">
										<span className="flaticon-web-programming"></span>
									</div> 

									
									<h3 className="h3-md statistic-number">3,<span className="count-element">87</span></h3>
									<p className="p-lg txt-400">Finished Projects</p>		

								</div>						
							</div>


							
							<div className="col">						
								<div className="statistic-block mb-40 wow fadeInUp">	

									
									<div className="statistic-ico ico-65">
										<span className="flaticon-shuttle"></span>
									</div> 

									
									<h3 className="h3-md statistic-number">1,<span className="count-element">340</span></h3>	
									<p className="p-lg txt-400">Websites Improved</p>																				
														
								</div>								
							</div>


							
							<div className="col">						
								<div className="statistic-block mb-40 wow fadeInUp">	

									
									<div className="statistic-ico ico-65">
										<span className="flaticon-speech-bubble-3"></span>
									</div> 

									
									<h3 className="h3-md statistic-number">4,<span className="count-element">17</span></h3>
									<p className="p-lg txt-400">Happy Customers</p>									
																			
								</div>							
							</div>


							
							<div className="col">						
								<div className="statistic-block mb-40 wow fadeInUp">	

									
									<div className="statistic-ico ico-65"><span className="flaticon-help"></span></div> 

									
									<h3 className="h3-md statistic-number">1,<span className="count-element">473</span></h3>
									<p className="p-lg txt-400">Tickets Closed</p>								
																			
								</div>							
							</div>


						</div>    
					</div>    
				</div>	   	
			</section>
    )
}

export default Stats;