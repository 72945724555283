import React from 'react'

function OptomizedSolutions(){
    return(
        <section id="services" className="pb-60 features-section division">
				<div className="container">


						
					<div className="row justify-content-center">	
						<div className="col-lg-10 col-xl-8">
							<div className="section-title title-01 mb-70">		

									
								<h2 className="h2-md">Optimized Business Sulutions</h2>	

									
								<p className="p-xl">Help improve your workflow with efficient software designed around your business needs and flow.
								</p>
									
							</div>	
						</div>
					</div>


					
			 		<div className="fbox-8-wrapper text-center">
			 			<div className="row row-cols-1 row-cols-md-3">


		 					
		 					<div className="col">
		 						<div className="fbox-8 mb-40 wow fadeInUp">

									
									<div className="fbox-img bg-whitesmoke-gradient">
										<img className="img-fluid" src="images/soft-design.png" alt="feature-icon" />
									</div>

									
									<h5 className="h5-md">Software Design</h5>
											
									
									<p className="p-lg">Solutions designed around your needs. Webpages, native apps, and desktop apps. From simple projects
										to enterprise application
									</p>

		 						</div>
		 					</div>


		 					
		 					<div className="col">
		 						<div className="fbox-8 mb-40 wow fadeInUp">

									
									<div className="fbox-img bg-whitesmoke-gradient">
										<img className="img-fluid" src="images/product-design.png" alt="feature-icon" />
									</div>

									
									<h5 className="h5-md">Product Design</h5>
											
									
									<p className="p-lg">Ground up product and package design.  3d rendering, prototyping and package design from inception 
										to production 
									</p>

		 						</div>
		 					</div>


		 					
		 					<div className="col">
		 						<div className="fbox-8 mb-40 wow fadeInUp">

									
									<div className="fbox-img bg-whitesmoke-gradient">
										<img className="img-fluid" src="images/hosting.png" alt="feature-icon" />
									</div>

									
									<h5 className="h5-md">Hosting</h5>
											
									
									<p className="p-lg">Hosting made simple.  Expansive hosting scaled to your needs from a simple 
										webpage to apps, and ecommerce
									</p>

		 						</div>
		 					</div>	


				 		</div>  	
				 	</div>		


				</div>	   		
			</section>
    )
}

export default OptomizedSolutions;