import React from 'react'
import { getFunctions, httpsCallable } from "firebase/functions";

import firebase from "../../firebase"

var initialState = { 
  firstName: '', 
  lastName: '' ,
  email: '',
  phone: '',
  message: ''
}

function Contact(){
  const [state, setState] = React.useState(initialState);
  const [submited, setSubmited] = React.useState(false)

  const handleSubmit = (event) => {
    event.preventDefault();

    document.getElementById("contactForm").reset();
    setState(initialState)
    setSubmited(true)

    const functions = getFunctions();
    const helloWorld = httpsCallable(functions, 'helloWorld');
    helloWorld(state).then((result) => {
      console.log(result)
    });
  };

  const handleChange = (event) => {
    const name = event.target.id;
    const value = event.target.value;
    console.log(value)
    setState({ ...state, [name]: value });
  };

  if(submited){
    return(
      <>
      <section id="contact" className="bg-whitesmoke content-2 pb-60 content-section division pt-20">
				<div className="container">
        <div class="section-title title-01 mb-10 mt-30"><h2 class="h2-md">Contact</h2><p class="p-xl">Thank you for reaching out, talk to you soon!</p></div>
        <img className="img-fluid mx-auto d-block rounded" src="images/submited.jpeg" alt="contact-image" />
      </div>

      </section>
      </>
    )
  }


  return(
    <>




    <section id="contact" className="bg-whitesmoke content-2 pb-60 content-section division pt-20">
				<div className="container">
					<div className="row d-flex align-items-center">


						
						<div className="col-md-5 col-lg-6">
							<div className="rel img-block left-column wow fadeInRight">
              <div class="section-title title-01 mb-10"><h2 class="h2-md">Contact</h2><p class="p-xl">We are here to help.</p></div>
              <form id="contactForm" onSubmit={handleSubmit} className='needs-validation' noValidate>
              <div className="form-group">
                <label htmlFor="email">Email address</label>
                <input type="email" onChange={handleChange} className="form-control was-validated" id="email" aria-describedby="emailHelp" placeholder="Enter email" required/>
                <small id="emailHelp" className="form-text text-muted">We'll never share your email with anyone else.</small>
              </div>
              <div className="form-group">
                <label htmlFor="phone">Phone Number</label>
                <input type="tel" onChange={handleChange} className="form-control" id="phone" placeholder="Enter phone #" required/>
              </div>
              <div className="form-group">
                <label htmlFor="firstName">First Name</label>
                <input type="text" onChange={handleChange} className="form-control" id="firstName" placeholder="Frist Name" required/>
              </div>
              <div className="form-group">
                <label htmlFor="lastName">Last Name</label>
                <input type="text" onChange={handleChange} className="form-control" id="lastName" placeholder="Last Name" required/>
              </div>
              <div className="form-group">
                <label htmlFor="message">Message</label>
                <textarea className="form-control" onChange={handleChange} id="message" rows="3"></textarea>
              </div>
              <div className="form-group mt-20 mb-10">
                <div className="row">
                  <div className="col-md-12">
                    <input type="submit" className="btn btn-skyblue tra-grey-hover float-end" />
                  </div>
                </div>
              </div>
            </form>
							</div>
						</div>


						
						<div className="col-md-7 col-lg-6">
							<div className="txt-block right-column wow fadeInLeft">

									
              <img className="img-fluid" src="images/contact.jpg" alt="contact-image" />

							</div>
						</div>	


					</div>	   
				</div>	   
			</section>



    </>





  )
}
export default Contact;