import React from 'react'

function CallToAction(){
    return(
        <section id="cta-3" className="cta-section division">
				<div className="cta-3-holder bg-lightgrey">
					<div className="container">
						<div className="bg-white cta-3-wrapper">
							<div className="row d-flex align-items-center">


								
								<div className="col-lg-7 col-lg-8">
									<div className="cta-3-txt">
										<h4 className="h4-xl">Call us now! and get your project started today!</h4>
									</div>
								</div>


								
								<div className="col-lg-4">
									<div className="text-end">
										<div className="cta-3-btn text-center">
											<a href="tel:254-307-7999" className="btn btn-skyblue tra-grey-hover">(254) 307-7999</a>
											
										</div>	
									</div>
								</div>


							</div>
						</div>    
					</div>	   
				</div>
			</section>
    )
}

export default CallToAction;