import React from 'react'

function Slider(){
    return(
        <section id="hero-3" className="bg-scroll hero-section division">
                <div className="container">	
                    <div className="row d-flex align-items-center">


                        
                        <div className="col-lg-6">
                            <div className="hero-3-txt white-color">

                                
                                <h2 className="h2-lg wow fadeInUp">Creative Web and App Development</h2>
                                <h3>Call Now <a href="tel:254-307-7999">(254) 307-7999</a></h3>

                                
                                <p className="p-xl wow fadeInUp">
                                Software Alternatives' turn-key web and app development services will add increased value to your business. Contact us today and transform your ideas into solutions! 
                                    
                                </p>

                                
                                <div className="btns-group mb-30 wow fadeInUp">
                                    <a href="tel:254-307-7999" className="btn btn-green tra-white-hover mr-15">Get Started</a>
                                    {/* <a href="https://www.youtube.com/watch?v=7e90gBu4pas" className="video-popup2 btn btn-md btn-transparent ico-20 ico-left">
                                        <span className="flaticon-play"></span> See how it works
                                    </a> */}
                                </div>

                                
                                <ul className="advantages clearfix wow fadeInUp">
                                    <li className="first-li"><p>Free consultation</p></li>
                                    <li><p>Exclusive Support</p></li>
                                    <li className="last-li"><p>Hosting</p></li>
                                </ul>

                            </div>
                        </div>	


                        
                        <div className="col-lg-6">	
                            <div className="hero-3-img wow fadeInRight">				
                                <img className="img-fluid" src="images/hero-3.png" alt="hero-image" />				
                            </div>
                        </div>


                    </div>	    
                </div>	   


                
                <div className="wave-shape-bottom">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 195"><path fill="#ffffff" fillOpacity="1" d="M0,192L1440,96L1440,320L0,320Z"></path></svg>
                </div>


        </section>
    )
}

export default Slider;