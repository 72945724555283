import React from 'react'

function Pricing(){
    return(
        <section id="pricing-2" className="bg-whitesmoke wide-60 pricing-section division">
				<div className="container">


						
					<div className="row justify-content-center">	
						<div className="col-lg-10 col-xl-8">
							<div className="section-title title-01 mb-80">		

								
								<h2 className="h2-md">Simple And Flexible Pricing</h2>	

									
								<p className="p-xl">Change or cancel your plan anytime</p>
									
							</div>	
						</div>
					</div>


					
					<div className="pricing-2-row pc-25">
						<div className="row row-cols-1 row-cols-md-3">


							
							<div className="col">
								<div className="pricing-2-table bg-white mb-40 wow fadeInUp">	
													
									
									<div className="pricing-plan">

										
										<div className="pricing-plan-title">
											<h5 className="h5-xs">Basic</h5>
											{/* <h6 className="h6-sm bg-lightgrey"></h6> */}
										</div>	

										
										<sup className="dark-color">$</sup>								
										<span className="dark-color">9</span>
										<sup className="validity dark-color"><span>.99</span> / month</sup>
										<p className="p-md">Billed as $119.88 per year</p>

									</div>	

									
									<ul className="features">
										<li><p className="p-md"><span>99.9%</span> Uptime</p></li>
										<li><p className="p-md"><span>1GB</span> Outbound Transfer</p></li>
										<li><p className="p-md"> Global CDN</p></li>
										<li><p className="p-md"><span>100</span> Build Minutes</p></li>
									</ul>

									
									

								</div>
							</div>	


							
							<div className="col">
								<div className="pricing-2-table bg-white mb-40 wow fadeInUp">	

									
									<div className="pricing-plan">

										
										<div className="pricing-plan-title">
											<h5 className="h5-xs">Advanced</h5>
											<h6 className="h6-sm bg-lightgrey">Save 10%</h6>
										</div>	

										
										<sup className="dark-color">$</sup>								
										<span className="dark-color">28</span>
										<sup className="validity dark-color"><span>.99</span> / month</sup>
										<p className="p-md">Billed as $347.88 per year</p>

									</div>	

									
									<ul className="features">
										<li><p className="p-md"><span>+</span> Basic</p></li>
										<li><p className="p-md"><span>40GB</span> Outbound Transfer</p></li>
										<li><p className="p-md"><span>Vertical</span> Scaling</p></li>
										<li><p className="p-md"><span>App</span> Hosting</p></li>
									</ul>

									
									

								</div>
							</div>	


							
							<div className="col">
								<div className="pricing-2-table bg-white mb-40 wow fadeInUp">

									
									<div className="pricing-plan highlight">

										
										<div className="pricing-plan-title">
											<h5 className="h5-xs">eCommerce</h5>
											<h6 className="h6-sm bg-green white-color">Popular</h6>
										</div>	

										
										<sup className="dark-color">$</sup>								
										<span className="dark-color">36</span>
										<sup className="validity dark-color"><span>.99</span> / month</sup>
										<p className="p-md">Billed as $443.88 per year</p>
									</div>	

									
									<ul className="features">
										<li><p className="p-md"><span>+Advanced</span> Projects</p></li>
										<li><p className="p-md"><span>100GB</span> Outbound Transfer</p></li>
										<li><p className="p-md"><span>Horizontal</span> Scaling</p></li>
										<li><p className="p-md"><span>High</span> Availability</p></li>	
									</ul>

									
									

								</div>
							</div>	


						</div>
					</div>	


					
					<div className="row">
						<div className="col-lg-10 offset-lg-1">
							<div className="pricing-notice text-center mb-40">					
								<p className="p-md">The above prices do not include applicable taxes based on your billing address.
								   
								</p>
							</div>	
						</div>
					</div>


					
					<div className="payment-methods pc-30">	
						<div className="row row-cols-1 row-cols-md-3">

							
							<div className="col col-lg-5">
								<div className="pbox mb-40">

									
									<h6 className="h6-md">Accepted Payment Methods</h6>

									
									<ul className="payment-icons ico-50">
										<li><img src="images/png-icons/visa.png" alt="payment-icon" /></li>
										<li><img src="images/png-icons/am.png" alt="payment-icon" /></li>
										<li><img src="images/png-icons/discover.png" alt="payment-icon" /></li>
										<li><img src="images/png-icons/paypal.png" alt="payment-icon" /></li>	
										<li><img src="images/png-icons/jcb.png" alt="payment-icon" /></li>
										
									</ul>

								</div>
							</div>	


							
							<div className="col col-lg-4">
								<div className="pbox mb-40">

									
									<h6 className="h6-md">Money Back Guarantee</h6>

									
									<p>Try Software Alternatives for 14 days. If it’s not a perfect fit, receive a full refund.</p>
									
								</div>
							</div>	


							
							<div className="col col-lg-3">
								<div className="pbox mb-40">

									
									<h6 className="h6-md">SSL Encrypted Payment</h6>

									
									<p>Your information is protected by 256-bit SSL encryption.</p>

								</div>
							</div>	

						</div>
					</div>	


				</div>	   
			</section>
    )
}

export default Pricing;