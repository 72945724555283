import React from 'react'

function Analytics(){
    return(
        <section id="analytics" className="content-7 bg-whitesmoke wide-60 content-section division">
				<div className="container">
					<div className="row d-flex align-items-center">

								
							
						<div className="col-md-6 order-last order-md-2">
							<div className="txt-block left-column wow fadeInLeft">

									
								<div className="txt-box mb-25">

									
									<h5 className="h5-lg">Advanced Analytics Review</h5>

										
									<p className="p-lg">Site Analytics enables you to get real time data with an account-based 
										lens so you can better evaluate website performance and personalize marketing efforts.
									</p>

								</div>

									
								<div className="txt-box">

									
									<h5 className="h5-lg">Search Engine Optimization (SEO)</h5>

									
									<ul className="simple-list">

										<li className="list-item">
											<p className="p-lg">specialized and custom digital marketing services to local, enterprise, 
												e-commerce and multinational businesses.  
											</p>
										</li>

										<li className="list-item">
											<p className="p-lg">Our ultimate goal with our SEO services is to help improve your platform and 
												enables your brand’s long-term success.
											</p>
										</li>

									</ul>

								</div>		


							</div>	
						</div>	


							
						<div className="col-md-6 order-first order-md-2">
							<div className="content-7-img wow fadeInRight">
								<img className="img-fluid" src="images/dashboard-01.png" alt="content-image" />
							</div>	
						</div>


					</div>	  
				</div>	   
			</section>
    )
}

export default Analytics;