import React from 'react'

function Stratagy(){
    return(
        <section id="stratagy" className="content-3 wide-60 content-section division">
				<div className="container">


					
					<div className="row justify-content-center">	
						<div className="col-lg-10 col-xl-8">
							<div className="section-title title-01 mb-70">		

								
								<h2 className="h2-md">Security, Simplicity, Easiness</h2>	

								
								<p className="p-xl">Get your life back!  Services that help you get ahold of your business data.
								</p>
									
							</div>	
						</div>
					</div>


					
					<div className="top-row pb-50">
						<div className="row d-flex align-items-center">


							
							<div className="col-md-5 col-lg-6">
								<div className="img-block left-column wow fadeInRight">
									<img className="img-fluid" src="images/img-14.png" alt="content-image" />
								</div>
							</div>


							
							<div className="col-md-7 col-lg-6">
								<div className="txt-block right-column wow fadeInLeft">

									
									<div className="txt-box mb-20">

											
										<h5 className="h5-lg">All-in-One Marketing Solutions</h5>

										
										<p className="p-lg">Easily create personalized experiences for every customer that visits 
											your site and nurture loyalty among top customers. Show them content meant just for 
											them, and encourage more action whether they are buying products, referring friends, 
											or leaving a review.
										</p>

									</div>

										
									<div className="txt-box">

										
										<h5 className="h5-lg">Strategy and Analytics Consulting</h5>

										
										<ul className="simple-list">

											<li className="list-item">
												<p className="p-lg">Understanding your analytics is key to optimizing your marketing stratagy 
													let us help you get a grasp on your data. 
												</p>
											</li>

											<li className="list-item">
												<p className="p-lg"> Having strategic measurements and reproting pulled from analytical data will give you a deeper insight on your business. 
												</p>
											</li>

										</ul>

									</div>		

								</div>
							</div>	


						</div>
					</div>	
				</div>
			</section>
    )
}

export default Stratagy;